.ementas_digitais {
  .NavigationItem {
    float: left;
    text-decoration: none;
    color: white;
    font-size: 14px;
    width: 275px;
    border: none !important;
    cursor: pointer;
    padding: 0px 0px;
    outline: none;

    img {
      width: 35px;
      background-color: transparent;
      margin-left: 0px;
      padding: 6px 4px;
      float: left;
      vertical-align: middle;
      border: 0;
    }

    .title {
      float: left;
      margin: 16px;
      font-size: 14px;
    }
  }

  .default {
    background: #83d5ed;
    border-left: solid 12px #82d5ee;
    border-top: solid 12px #82d5ee;
  }

  .bread {
    background: #e09b38;
    border-left: solid 12px #c18434;
    border-top: solid 12px #c18434;
  }

  .covered {
    background: #5a8fd2;
    border-left: solid 12px #4d75a9;
    border-top: solid 12px #4d75a9;
  }

  .dessert {
    background: #714b80;
    border-left: solid 12px #543a60;
    border-top: solid 12px #543a60;
  }

  .drinks {
    background: #4c9c9c;
    border-left: solid 12px #397575;
    border-top: solid 12px #397575;
  }

  .fish {
    background: #bf6b90;
    border-left: solid 12px #9b5a79;
    border-top: solid 12px #9b5a79;
  }

  .meat {
    background: #72bbbc;
    border-left: solid 12px #5d9b9b;
    border-top: solid 12px #5d9b9b;
  }

  .salad {
    background: #2ecc71;
    border-left: solid 12px #27ae60;
    border-top: solid 12px #27ae60;
  }

  .soup {
    background: #c3cb33;
    border-left: solid 12px #a0a022;
    border-top: solid 12px #a0a022;
  }

  .arrow {
    float: left;
    margin: 0 0 5px 250px;
    vertical-align: middle;
    width: 0;
    height: 0;
    background: none !important;
    border-bottom: solid 12px transparent;
    border-right: solid 12px transparent;
  }
}
@media screen and (max-height: 800px) {
  .ementas_digitais {
    .NavigationItem {
      width: 267px;

      img {
        padding: 4px;
      }

      .title {
        margin: 14px 16px;
      }
    }

    .arrow {
      border-width: 9px;
      margin-bottom: 7px;
    }
  }
}

.chef_house,
.chef_house_vertical {
  .NavigationItem {
    font-family: "Helvetica";
    background: #ffffff;
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: #b6b8ba;
    font-size: 18px;
    text-transform: uppercase;
    width: 100%;
    border: none !important;
    cursor: pointer;
    padding: 0px;
    outline: none;
    letter-spacing: 2px;

    .arrow {
      font-family: "FontAwesome-Light";
    }

    .title {
      margin: 16px;
      font-size: 18px;

      &.selected {
        color: #000000;
        text-decoration: none;
        border-bottom: 3px solid #000000;
      }
    }
  }
}
