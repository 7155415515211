.ementas_digitais {
  .DrinkItem {
    text-decoration: none;
    color: white;
    background: #4c9c9c;
    font-size: 14px;
    width: 250px;
    border: none !important;
    cursor: pointer;
    margin-bottom: 25px;
    padding: 0px 0px;
    outline: none;

    img {
      width: 35px;
      background-color: transparent;
      margin-left: 0px;
      padding: 7px 4px;
      float: left;
      vertical-align: middle;
      border: 0;
    }

    .title {
      float: left;
      margin: 16px;
      font-size: 14px;
    }
  }
}

.chef_house,
.chef_house_vertical {
  .DrinkItem {
    font-family: "Helvetica";
    background: #ffffff;
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: #b6b8ba;
    font-size: 18px;
    text-transform: uppercase;
    width: 100%;
    border: none !important;
    cursor: pointer;
    padding: 0px;
    outline: none;

    .arrow {
      font-family: "FontAwesome-Light";
    }

    .title {
      margin: 16px;
      font-size: 18px;
      letter-spacing: 2px;

      &.selected {
        color: #000000;
        text-decoration: none;
        border-bottom: 3px solid #000000;
      }
    }
  }
}

@media screen and (max-height: 800px) {
  .DrinkItem {
    img {
      padding: 4px;
    }

    .title {
      font-size: 12px;
      margin: 14px 2px;
    }
  }
}
