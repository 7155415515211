@keyframes appear {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.ementas_digitais {
  .hide {
    display: none;
  }

  .FoodItem {
    float: left;
    width: 225px;
    height: 300px;
    margin-left: 12px;
    cursor: pointer;
    animation: appear 0.25s;
  }

  .FoodItemImageWrapper {
    float: left;
    width: 100%;
    height: 200px;
    overflow: hidden;
  }

  .FoodItemImage {
    width: 100%;
    height: 100%;
    transform: scale(1.25);
    background-size: cover;
    background-position: center;
  }

  .FoodItemName {
    float: left;
    display: table;
    color: white;
    width: 100%;
    height: 65px;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    transition: background-color 120s step-end;

    p {
      display: table-cell;
      vertical-align: middle;
      padding: 6px;
    }
  }

  .default {
    background: #83d5ed;
  }

  .bread {
    background: #e09b38;
  }

  .covered {
    background: #5a8fd2;
  }

  .dessert {
    background: #714b80;
  }

  .drinks {
    background: #4c9c9c;
  }

  .fish {
    background: #bf6b90;
  }

  .meat {
    background: #72bbbc;
  }

  .salad {
    background: #2ecc71;
  }

  .soup {
    background: #c3cb33;
  }
}

.chef_house {
  .hide {
    display: none;
  }

  .FoodItem {
    font-family: "Helvetica";
    float: left;
    width: 275px;
    height: 365px;
    margin-left: 12px;
    cursor: pointer;
    animation: appear 0.25s;
  }

  .FoodItemImageWrapper {
    float: left;
    width: 100%;
    height: 200px;
    overflow: hidden;
  }

  .FoodItemImage {
    width: 100%;
    height: 200px;
    background-position: center;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: contain;
    transform: scale(1.25);
  }

  .FoodItemName {
    float: left;
    color: #000000;
    width: calc(100% - 12px);
    display: table;
    height: 135px;
    text-align: center;
    margin: 0;
    font-size: 12px;
    padding: 6px;

    span {
      display: table-cell;
      margin-top: 6px;
    }

    .itemName {
      font-family: "Helvetica-BoldOblique";
      font-size: 18px;
      font-weight: 900;
      float: left;
      width: 100%;
    }

    .itemPrice {
      float: left;
      font-size: 12px;
      width: 100%;
    }

    .itemMore {
      float: left;
      color: #b6b8ba;
      width: 100%;
    }
  }

  .default {
    background: #ffffff;
    position: relative;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-flow: column;
    justify-content: center;

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
      bottom: 0px;
      left: 10%;
      right: 10%;
      width: 80%;
      height: 50%;
      -moz-border-radius: 100%;
      border-radius: 100%;
    }
  }
}

.chef_house_vertical {
  .hide {
    display: none;
  }

  .FoodItem {
    font-family: "Helvetica";
    float: left;
    width: 275px;
    height: 365px;
    margin-left: 12px;
    cursor: pointer;
    animation: appear 0.25s;
  }

  .FoodItemImageWrapper {
    float: left;
    width: 100%;
    height: 200px;
    overflow: hidden;
  }

  .FoodItemImage {
    width: 100%;
    height: 200px;
    background-position: center;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: contain;
    transform: scale(1.25);
  }

  .FoodItemName {
    float: left;
    color: #000000;
    width: calc(100% - 12px);
    display: table;
    height: 135px;
    text-align: center;
    margin: 0;
    font-size: 12px;
    padding: 6px;

    span {
      display: table-cell;
      margin-top: 6px;
    }

    .itemName {
      font-family: "Helvetica-BoldOblique";
      font-size: 18px;
      font-weight: 900;
      float: left;
      width: 100%;
    }

    .itemPrice {
      float: left;
      font-size: 12px;
      width: 100%;
    }

    .itemMore {
      float: left;
      color: #b6b8ba;
      width: 100%;
    }
  }

  .default {
    background: #ffffff;
    position: relative;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-flow: column;
    justify-content: center;

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
      bottom: 0px;
      left: 10%;
      right: 10%;
      width: 80%;
      height: 50%;
      -moz-border-radius: 100%;
      border-radius: 100%;
    }
  }
}
