.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(-100%);
}

.ementas_digitais_DrinkItems {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 250px;
  height: 90%;
  z-index: 10;
  background-color: #393b4f;
  transition: transform 0.2s ease-out;
}

.ementas_digitais_BackButton {
  text-decoration: none;
  color: white;
  background: #b9c7b6;
  font-size: 14px;
  width: 250px;
  border: none !important;
  cursor: pointer;
  margin-bottom: 25px;
  padding: 0px 0px;
  outline: none;
  min-height: 49px;
  text-align: left;

  .title {
    float: left;
    margin: 15px;
    font-size: 14px;
  }
}

.chef_house_DrinkItems {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 354px;
  height: calc(100% - 160px);
  z-index: 10;
  background-color: #ffffff;
  transition: transform 0.2s ease-out;
}

.chef_house_BackButton {
  font-family: "Helvetica-Light";
  width: 50%;
  margin: 0 12px;
  box-shadow: 0 0 0 2px #b6b8ba;
  border-radius: 25px;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  color: #b6b8ba;
  background: transparent;
  margin: 12px 25%;
  outline: none;
  height: 28px;
  letter-spacing: 2px;

  .arrow {
    font-family: "FontAwesome-Light";
    margin-right: 6px;
  }

  .title {
    width: 100%;
    line-height: 14px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
  }
}

.chef_house_vertical_DrinkItems {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 160px);
  z-index: 10;
  background-color: #ffffff;
  transition: transform 0.2s ease-out;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.chef_house_vertical_BackButton {
  font-family: "Helvetica-Light";
  margin: 0 12px;
  box-shadow: 0 0 0 2px #b6b8ba;
  border-radius: 25px;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  color: #b6b8ba;
  background: transparent;
  margin: 12px 25px;
  outline: none;
  height: 28px;
  letter-spacing: 2px;
  // position: absolute;
  top: 0;
  left: 0;

  .arrow {
    font-family: "FontAwesome-Light";
    margin-right: 6px;
  }

  .title {
    width: 100%;
    line-height: 14px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
  }
}
