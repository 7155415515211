.chef_house,
.chef_house_vertical {
  .WelcomeBanner {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 90vw;
    height: 90vh;
    left: 5%;
    top: 5%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
  }
}
