.ementas_digitais {
  .FoodItems {
    float: left;
    width: calc(100% - 325px);
    margin-left: 275px;
    padding: 25px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: stretch;
  }
}

.chef_house {
  .FoodItems {
    float: left;
    font-family: "Helvetica";
    width: calc(100% - 375px);
    margin-left: 325px;
    padding: 25px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: stretch;
  }

  .WineFilters {
    width: 100%;
    margin-top: 12px;
    margin-left: 25px;
    float: left;
    display: flex;
    justify-content: center;
  }

  .SakeFilters {
    width: 100%;
    margin-top: 12px;
    margin-left: 25px;
    float: left;
    display: flex;
    justify-content: center;
  }
}

.chef_house_vertical {
  display: flex;

  .group {
    padding: 50px 25px;
  }

  .FoodItems {
    font-family: "Helvetica";
    width: calc(100% - 50px);
    display: flex;
    padding: 50px 25px;
    flex-flow: row wrap;
    justify-content: center;
    align-content: stretch;
  }

  .WineFilters {
    width: 100%;
    margin-top: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .SakeFilters {
    width: 100%;
    margin-top: 12px;
    margin-left: 25px;
    float: left;
    display: flex;
    justify-content: center;
  }
}
