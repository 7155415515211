.ementas_digitais {
  .SideDrawer {
    float: left;
    position: fixed;
    background-color: #492e1d;
    width: 250px;
    height: 100%;

    h6 {
      margin: 6px 0;
      color: #8185a3;
      text-align: center;
    }

    p {
      width: calc(250px - 12px);
      color: #ffffff;
      font-size: 9px;
      padding: 6px;
      text-align: left;
      position: fixed;
      bottom: 0;
    }
  }

  .Logo {
    margin: 6px;
    text-align: center;
    background-color: #ffffff;

    img {
      height: 50px;
    }
  }

  .welcomeText {
    float: left;
    width: calc(100% - 325px);
    margin-left: 275px;
    padding: 25px;
    text-align: center;

    h1 {
      font-size: 36px;
      font-weight: 500;
      margin: 0 0 10px 0;
    }

    h4 {
      font-size: 18px;
      font-weight: 500;
      margin: 0 0 10px 0;
    }

    h5 {
      font-size: 14px;
      font-weight: 500;
      margin: 0 0 10px 0;
    }

    a {
      width: 100%;
      float: left;
      color: #337ab7;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: #23527c;
        text-decoration: underline;
      }
    }
  }
}

.chef_house {
  .SideDrawer {
    font-family: "Helvetica";
    float: left;
    position: fixed;
    background-color: #eeeeee;
    height: 100%;

    h6 {
      margin: 6px 0;
      color: #8185a3;
      text-align: center;
    }

    p {
      width: calc(250px - 12px);
      color: #000000;
      font-size: 9px;
      padding: 6px;
      text-align: left;
      position: fixed;
      bottom: 0;
    }
  }

  .wrapper {
    float: left;
    position: relative;
    background-color: #ffffff;
    width: 354px;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      top: 15%;
      right: 0;
      width: 10%;
      height: 70%;
      background: transparent;
    }
  }

  .Logo {
    display: inline-block;
    width: 100%;
    height: 140px;
    padding: 0px;
    margin: 6px;
    text-align: center;

    .imageWrapper {
      width: 100%;
      height: 120px;
      float: left;

      img {
        height: 100%;
        max-width: 100%;
      }
    }

    .imageBottomDecorator {
      width: 100%;
      height: 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      .decorator {
        width: 95px;
        height: 100%;
        margin-top: 6px;
        background-color: #ffffff;
        background-image: url("../../assets/images/shadow.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  .Disclaimer {
    font-family: "Helvetica";
    position: fixed;
    float: left;
    width: calc(354px - 96px);
    bottom: 0;
    left: 0;
    color: #eeeeee;

    .disclaimerText {
      font-style: italic;
    }

    &.selected {
      padding: 96px 48px;
      background: rgba(0, 0, 0, 0.8);

      .viewOrder {
        visibility: hidden;
      }
    }
  }

  .Info {
    position: fixed;
    left: 12px;
    bottom: 12px;
    width: 24px;
    height: 24px;
    border: 1px solid #b6b8ba;
    border-radius: 50%;
    padding: 4px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background: url("../../assets/images/info-sign.svg");
    background-size: 24px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .viewOrder {
    position: fixed;
    left: calc(354px - 142px);
    bottom: 2px;
    display: flex;
    align-items: center;
    height: 56px;
    visibility: visible;
    cursor: pointer;
  }

  .viewOrderText {
    font-family: "Helvetica-Light";
    color: #b6b8ba;
    float: left;

    &.selected {
      color: #000000;
    }
  }

  .viewOrderIcon {
    width: 48px;
    height: 48px;
    padding: 4px;
    display: flex;
    justify-content: center;
    background: url("../../assets/images/view-order-icon.svg");
    background-size: 48px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    float: left;

    &.selected {
      background: url("../../assets/images/view-order-icon-selected.svg");
    }
  }

  .welcomeText {
    display: none;
  }

  .welcomeBanner {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.chef_house_vertical {
  .SideDrawer {
    font-family: "Helvetica";
    background-color: #eeeeee;
    width: 100%;

    h6 {
      margin: 6px 0;
      color: #8185a3;
      text-align: center;
    }

    p {
      width: calc(250px - 12px);
      color: #000000;
      font-size: 9px;
      padding: 6px;
      text-align: left;
      position: fixed;
      bottom: 0;
    }
  }

  .wrapper {
    position: relative;
    background-color: #ffffff;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-bottom: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Add shadow to the bottom center

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      top: 15%;
      right: 0;
      width: 10%;
      height: 70%;
      background: transparent;
    }
  }

  .Logo {
    display: inline-block;
    width: 100%;
    height: 140px;
    padding: 0px;
    margin: 25px auto 6px;
    text-align: center;

    .imageWrapper {
      width: 100%;
      height: 120px;
      float: left;

      img {
        height: 100%;
        max-width: 100%;
      }
    }

    .imageBottomDecorator {
      width: 100%;
      height: 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      .decorator {
        width: 95px;
        height: 100%;
        margin-top: 6px;
        background-color: #ffffff;
        background-image: url("../../assets/images/shadow.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  .Disclaimer {
    font-family: "Helvetica";
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    color: #eeeeee;

    .disclaimerText {
      font-style: italic;
    }

    &.selected {
      padding: 96px 48px;
      background: rgba(0, 0, 0, 0.8);

      .viewOrder {
        visibility: hidden;
      }
    }
  }

  .Info {
    position: fixed;
    left: 25px;
    top: 25px;
    width: 24px;
    height: 24px;
    border: 1px solid #b6b8ba;
    border-radius: 50%;
    padding: 4px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background: url("../../assets/images/info-sign.svg");
    background-size: 24px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .viewOrder {
    position: fixed;
    left: calc(354px - 142px);
    bottom: 2px;
    display: flex;
    align-items: center;
    height: 56px;
    visibility: visible;
    cursor: pointer;
  }

  .viewOrderText {
    font-family: "Helvetica-Light";
    color: #b6b8ba;
    float: left;

    &.selected {
      color: #000000;
    }
  }

  .viewOrderIcon {
    width: 48px;
    height: 48px;
    padding: 4px;
    display: flex;
    justify-content: center;
    background: url("../../assets/images/view-order-icon.svg");
    background-size: 48px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    float: left;

    &.selected {
      background: url("../../assets/images/view-order-icon-selected.svg");
    }
  }

  .welcomeText {
    display: none;
  }

  .welcomeBanner {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
}
