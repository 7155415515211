.chef_house,
.chef_house_vertical {
  .OrderItems {
    float: left;
    font-family: "Helvetica";
    width: calc(100% - 400px);
    margin-left: 354px;
    padding: 25px;
    height: calc(100vh - 124px);
    margin-bottom: 12px;
    overflow: scroll;
  }
  .OrderItemsRemoveAll {
    float: left;
    font-family: "Helvetica";
    width: calc(100% - 400px);
    margin-left: 354px;
    padding: 0 25px;
    margin-top: 12px;
    height: 48px;
  }

  .OrderItemsRemoveAllIcon {
    width: 32px;
    height: 48px;
    padding: 4px;
    margin-top: -14px;
    display: flex;
    justify-content: center;
    background: url("../../assets/images/delete-order-icon.svg");
    background-size: 48px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    float: right;
  }
}
