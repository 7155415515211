.ementas_digitais {
  .WineItem {
    text-decoration: none;
    color: white;
    background: #4c9c9c;
    font-size: 14px;
    width: 125px;
    float: left;
    border: none !important;
    cursor: pointer;
    margin-bottom: 25px;
    padding: 0px 0px;
    outline: none;
    min-height: 49px;

    .title {
      float: left;
      margin: 15px;
      font-size: 16px;
    }
  }

  .espumante {
    background: #b9c7b6;
  }

  .green_wine {
    background: #bac13a;
  }

  .red_wine {
    background: #900000;
  }

  .rose {
    background: #f88a54;
  }

  .white_wine {
    background: #dfd2a2;
  }
}

.chef_house {
  .WineItem {
    font-family: "Helvetica";
    margin: 0 6px 24px 6px;
    box-shadow: 0 0 0 2px #b6b8ba;
    border-radius: 25px;
    padding: 10px 25px;
    border: none;
    cursor: pointer;
    color: #b6b8ba;
    font-weight: 600;
    background: transparent;
    outline: none;
    height: 32px;

    .title {
      width: 100%;
      line-height: 14px;
      text-align: center;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    &.selected {
      color: #000000;
      box-shadow: 0 0 0 2px #000000;
    }
  }
}

.chef_house_vertical {
  .WineItem {
    font-family: "Helvetica";
    margin: 0 6px 24px 6px;
    box-shadow: 0 0 0 2px #b6b8ba;
    border-radius: 25px;
    padding: 10px 25px;
    border: none;
    cursor: pointer;
    color: #b6b8ba;
    font-weight: 600;
    background: transparent;
    outline: none;
    height: 32px;
    width: 156px;

    .title {
      width: 100%;
      line-height: 14px;
      text-align: center;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    &.selected {
      color: #000000;
      box-shadow: 0 0 0 2px #000000;
    }
  }
}
