.relative {
  position: relative;
}

.navigationList {
  list-style: none;
  margin: 0;
  padding: 0 25px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  justify-content: center;
  flex-flow: row wrap;
}
