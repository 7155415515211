.chef_house,
.chef_house_vertical {
  .SakeFilters {
    width: 75%;
    margin: 18px 12.5%;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .SakeItems {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 354px;
    height: calc(100% - 160px);
    z-index: 20;
    background-color: #ffffff;
    transition: transform 0.2s ease-out;
  }

  .Open {
    transform: translateX(0);
  }

  .Close {
    transform: translateX(-100%);
  }
}

.chef_house_BackButton,
.chef_house_vertical_BackButton {
  font-family: "Helvetica-Light";
  width: 50%;
  margin: 0 12px;
  box-shadow: 0 0 0 2px #b6b8ba;
  border-radius: 25px;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  color: #b6b8ba;
  background: transparent;
  margin: 12px 25%;
  outline: none;
  height: 28px;
  letter-spacing: 2px;

  .arrow {
    font-family: "FontAwesome-Light";
    margin-right: 6px;
  }

  .title {
    width: 100%;
    line-height: 14px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
  }
}
