.chef_house,
.chef_house_vertical {
  .FoodItemDetails {
    font-family: "Helvetica";
    position: relative;

    .foodImage {
      float: left;
      width: 100%;
      display: flex;
      justify-content: center;
      height: 410px;
    }

    .foodMain {
      width: 100%;
      float: left;
      position: relative;
      background: #ffffff;
      margin-top: 24px;

      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        bottom: 0px;
        left: 35%;
        right: 10%;
        width: 30%;
        height: 50%;
        -moz-border-radius: 100%;
        border-radius: 100%;
      }
    }

    .foodInfo {
      width: 100%;
      float: left;
    }

    .foodName {
      font-family: "Helvetica-BoldOblique";
      width: 100%;
      float: left;
      font-size: 20px;
      text-align: center;
      margin: 12px 0 6px 0;
    }

    .foodPrice {
      width: calc(100% - 24px);
      float: left;
      font-size: 14px;
      color: #000000;
      display: flex;
      justify-content: center;
      margin-bottom: 12px;
      padding: 0 12px;

      span {
        float: left;
        margin-right: 6px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .foodNutrition {
      font-family: "Helvetica-Light";
      margin: 0 12.5% 24px 12.5%;
      box-shadow: 0 0 0 2px #b6b8ba;
      border-radius: 25px;
      padding: 8px 10px;
      border: none;
      cursor: pointer;
      color: #b6b8ba;
      text-align: center;
      background: transparent;
      outline: none;
      height: 14px;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    .foodDescription {
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      padding: 12px 16px;
      font-size: 14px;
      position: relative;
      margin: 0;
    }

    .orderControls {
      position: relative;
      height: 32px;
      margin: 0 12.5% 24px 12.5%;
    }

    .orderAmountControls {
      position: absolute;
      top: 0;
      left: 0;
      width: 40%;
      margin-right: 12px;
      display: inline-block;
    }

    .changeOrderAmountBtn {
      float: left;
      font-family: "Helvetica-Light";
      box-shadow: 0 0 0 2px #b6b8ba;
      border-radius: 25px;
      padding: 0 18px;
      border: none;
      cursor: pointer;
      color: #b6b8ba;
      text-align: center;
      background: transparent;
      outline: none;
      height: 32px;
      width: 48px;
      font-size: 18px;
      line-height: 0;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    .orderAmount {
      float: left;
      font-family: "Helvetica-Light";
      margin: 0 12px;
      text-align: center;
      width: 32px;
      color: #000000;
      line-height: 36px;
    }

    .changeOrderDosageCloseWrapper {
      position: absolute;
      top: 0;
      left: 40%;
      font-family: "Helvetica";
      box-shadow: 0 0 0 2px #b6b8ba;
      background: transparent;
      border-radius: 25px;
      padding: 0px 12px;
      border: none;
      cursor: pointer;
      outline: none;
      height: 32px;
      width: 134px;
      font-size: 12px;
      line-height: 34px;
      color: #000000;
    }

    .changeOrderDosageClose {
      width: 100%;
    }

    .changeOrderDosageCloseText {
      float: left;
      width: 90%;
    }

    .changeOrderDosageOpenWrapper {
      position: absolute;
      bottom: 0;
      left: 40%;
      font-family: "Helvetica";
      box-shadow: 0 0 0 2px #b6b8ba;
      background: #ffffff;
      border-radius: 25px;
      padding: 0px 12px;
      border: none;
      cursor: pointer;
      outline: none;
      width: 134px;
      font-size: 12px;
      line-height: 34px;
      color: #000000;

      div {
        color: #b6b8ba;
        padding: 12px 0;
      }
    }

    .changeOrderDosageOpen {
      width: 100%;
    }

    .changeOrderDosageOpenText {
      float: left;
      width: 90%;
      border-top: 1px solid #b6b8ba;
      padding-top: 6px;
      color: #000000;
    }

    .chevron {
      float: left;
      color: #000000;
      width: 10%;

      &:before {
        border-style: solid;
        border-width: 0.25em 0.25em 0 0;
        content: "";
        display: inline-block;
        height: 0.45em;
        left: 0.15em;
        position: relative;
        top: 0.15em;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 0.45em;
        top: 20px;
      }

      &.bottom {
        &:before {
          transform: rotate(135deg);
          top: 10px;
        }
      }
    }

    .orderBtn {
      position: absolute;
      top: 0;
      right: 0;
      font-family: "Helvetica-Light";
      box-shadow: 0 0 0 2px #000000;
      background: #000000;
      border-radius: 25px;
      padding: 0 18px;
      border: none;
      cursor: pointer;
      color: #b6b8ba;
      text-align: center;
      outline: none;
      height: 32px;
      font-size: 12px;
      line-height: 32px;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    .tooltip {
      width: calc(80% - 24px);
      height: calc(100% - 68px);
      background-color: #ffffff;
      color: #000000;
      margin: 24px 10%;
      padding: 12px;
      position: fixed;
      z-index: 600;
      font-size: 12px;
      text-align: center;
      top: 0;
      left: 0;
      border: 1px solid transparent;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }

    .tooltipTitle {
      width: 100%;
      float: left;

      h2 {
        text-align: center;
        width: 100%;
        float: left;
        font-size: 18px;
        font-family: "Helvetica-BoldOblique";
        margin: 6px;
      }
    }

    .allergies {
      float: left;
      width: 100%;
      position: relative;
      background: #ffffff;
      padding-bottom: 24px;
      margin-bottom: 24px;

      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        bottom: 0px;
        left: 35%;
        right: 10%;
        width: 30%;
        height: 50%;
        -moz-border-radius: 100%;
        border-radius: 100%;
      }
    }

    .tooltipAllergen {
      float: left;
      width: 100%;
      padding: 6px;

      span {
        text-align: center;
        font-size: 14px;
        float: left;
        width: 100%;
      }
    }
    .nutrition {
      float: left;
      width: 100%;
      margin-bottom: 12px;
    }
    .tooltipNutrition {
      font-family: "Helvetica";
      border-collapse: separate;
      border-spacing: 10px;
      width: 50%;
      margin: 6px 25%;
      float: left;

      td {
        border-bottom: 1px solid #b6b8ba;
        padding: 6px;
      }
    }
  }

  .nutritionType {
    text-align: right;
  }

  .nutritionValue {
    text-align: center;
  }

  .nutritionInfo {
    font-family: "Helvetica-BoldOblique";
  }

  .arrow {
    font-family: "FontAwesome-Light";
    margin-right: 6px;
  }

  .backButton {
    font-family: "Helvetica-Light";
    margin: 0 35% 24px 35%;
    box-shadow: 0 0 0 2px #b6b8ba;
    border-radius: 25px;
    padding: 8px 10px;
    border: none;
    cursor: pointer;
    color: #b6b8ba;
    text-align: center;
    background: transparent;
    outline: none;
    height: 28px;
    margin-top: 6px;
    letter-spacing: 2px;

    .title {
      width: 100%;
      line-height: 14px;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}
